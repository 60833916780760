import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarActionEnum} from "../../core/enums/snackbar-action.enum";


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() fileChangeEmitter = new EventEmitter<File[]>();
  files: File[] = [];
  fileUploaded: boolean = false;

  constructor(private snackbar: MatSnackBar) {}

  ngOnInit() {}

  //this any type is here because this is a custom event and the docs provide no actual type for it.
  onFilesAdded(event: any): void {
    if (this.fileUploaded) {
      this.snackbar.open('Only one PO file can be uploaded, please remove the file to upload a different PO.', SnackbarActionEnum.ALERT, {duration: 3600})
      return;
    }
    const fileArray: File[] = [];
    fileArray.push(...event.addedFiles);
    fileArray.forEach((each: File, i: number) => {
      const validFile = this.detectFileType(fileArray[i].name);
      if (validFile) {
        this.files.push(fileArray[i]);
      } else {
        alert(`File: ${fileArray[i].name} is of an invalid type! Please upload only PDF, CAD, or Zip files.`);
      }
    });
    this.fileChangeEmitter.emit(fileArray);
    this.fileUploaded = true;
  }

  detectFileType(name: string): RegExpExecArray | null {
    let allowedExtensions = /(\.pdf|\.cad|\.zip)$/i;
    return allowedExtensions.exec(name);
  }

  onRemove(file: File): void {
    this.files.splice(this.files.indexOf(file), 1);
    this.fileChangeEmitter.emit(this.files);
    this.fileUploaded = false;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }


}
