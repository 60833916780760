import {ShipmentAccessorial} from "./shipment-accessorial.interface";
import {ShipmentHandlingUnit} from "./shipment-handling-unit.interface";

export class Shipment {
  id?: number;
  shipmentOrderNo?: string;
  originSiteCode?: string;
  originName?: string;
  originAddress1?: string;
  originAddress2?: string;
  originCity?: string;
  originState?: string;
  originPostal?: string;
  originCountry?: string;
  originContactName?: string;
  originContactEmail?: string;
  originContactPhone?: string;
  originContactPhoneExt?: string;
  originOpenTime?: string;
  originCloseTime?: string;
  originCallForAppt?: string;
  destinationSiteCode?: string;
  destinationName?: string;
  destinationAddress1?: string;
  destinationAddress2?: string;
  destinationCity?: string;
  destinationState?: string;
  destinationPostal?: string;
  destinationCountry?: string;
  destinationContactName?: string;
  destinationContactEmail?: string;
  destinationContactPhone?: string;
  destinationContactPhoneExt?: string;
  destinationOpenTime?: string;
  destinationCloseTime?: string;
  destinationCallForAppt?: string;
  billToSiteCode?: string;
  billToName?: string;
  billToAddress1?: string;
  billToAddress2?: string;
  billToCity?: string;
  billToState?: string;
  billToPostal?: string;
  billToCountry?: string;
  billToContactName?: string;
  billToContactEmail?: string;
  billToContactPhone?: string;
  billToContactPhoneExt?: string;
  billToOpenTime?: string;
  billToCloseTime?: string;
  billToCallForAppt?: string;
  equipmentType?: string;
  pickupDateTime?: Date;
  pickupTimezone?: string;
  deliveryDateTime?: string;
  deliveryTimezone?: string;
  totalLinearDimension?: number;
  dUnit?: string;
  totalWeight?: number;
  wUnit?: string;
  terms?: string;
  direction?: string;
  instructions?: string;
  shipmentAccessorials?: ShipmentAccessorial[];
  shipmentHandlingUnits?: ShipmentHandlingUnit[];

}
