import {Component, Inject} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-request-changes-dialog',
  templateUrl: './request-changes-dialog.component.html',
  styleUrls: ['./request-changes-dialog.component.scss']
})
export class RequestChangesDialogComponent {
  changeRequestForm = this.fb.group({
    name: ['', Validators.required],
    changeDescription: ['', Validators.required],
    floorPlanModification: ['']
  });

  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<RequestChangesDialogComponent>) {}

  sendRequest(): void {
    if (this.changeRequestForm.valid) {
      this.dialogRef.close(this.changeRequestForm.value);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
