import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {QuoteService} from '../core/services/quote.service';
import {ApproveDialogComponent} from "./dialogs/approve-dialog/approve-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {RequestChangesDialogComponent} from "./dialogs/request-changes-dialog/request-changes-dialog.component";
import {ChangeRequestInfo} from "../core/models/change-request-info.interface";
import {EstimateDateOption} from "../core/models/estimate-date-option.interface";
import {FormBuilder, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarActionEnum} from "../core/enums/snackbar-action.enum";
import {PdfViewerComponent} from "ng2-pdf-viewer";
import {ApprovalInfo} from '../core/models/approval-info.interface';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  @ViewChild(PdfViewerComponent, { static: false }) pdfViewer: PdfViewerComponent | undefined;

  quoteId: number | undefined;
  pdfRetrieved: boolean = false;
  pdfSrc: any;
  fileArray: File[] = [];

  dateOptions: EstimateDateOption[] = [];
  isLoading: boolean = true;

  approvalForm = this.fb.group({
    name: [null, Validators.required],
    email: [null, Validators.required],
    installDate: [null],
    certified: [null, Validators.required],
    estimateDate: [null],
    po: [null],
    uploadPo: [null]
  });

  constructor(private route: ActivatedRoute,
              private quoteService: QuoteService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private snackbar: MatSnackBar) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.quoteId = params['id'];
      if (this.quoteId) {
        this.quoteService.getPdf(this.quoteId).subscribe((response) => {
          this.pdfSrc = response;
        })
      }
    });

    this.dateOptions = this.quoteService.getDateRangeOptions();
  }

  approveQuote(): void {
    if (!this.approvalForm.touched || !this.approvalForm.valid) {
      this.snackbar.open('Please fill out all required fields before submitting', SnackbarActionEnum.ALERT, {duration: 3600});
      return;
    } else {
      const dialogRef = this.dialog.open(ApproveDialogComponent, {
        data: {
          dateOptions: this.dateOptions
        },
        width: '15vw'
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result && this.quoteId) {

          let installDate: string;
          let isEstimate: boolean;

          if (this.approvalForm.get('installDate')?.value) {
            installDate = this.approvalForm.get('installDate')?.value!;
            isEstimate = false;
          }  else {
            installDate = this.approvalForm.get('estimateDate')?.value!;
            isEstimate = true;
          }

          const approvalInfo: ApprovalInfo = {
            name: this.approvalForm.get('name')?.value ?? '',
            email: this.approvalForm.get('email')?.value ?? '',
            installDate: new Date(installDate),
            isEstimate: isEstimate,
            po: this.approvalForm.get('po')?.value ?? '',
            certified: result,
            documentName: '',
            documentHash: ''
          };

          const formData: FormData = new FormData();

          this.fileArray.length ?
            formData.append('files', this.fileArray[0], this.fileArray[0].name) :
            formData.append('files', '');

          formData.append('body', JSON.stringify(approvalInfo));

          this.quoteService.submitApproval(this.quoteId, formData).subscribe(
            (result) => {
              result ? this.snackbar.open('Successfully submitted approval', SnackbarActionEnum.SUCCESS, {duration: 3600}) :
                this.snackbar.open('Failed to submit approval', SnackbarActionEnum.ERROR, {duration: 3600});
            },
            (err) => {
              console.log(err);
              this.snackbar.open('Failed to submit approval', SnackbarActionEnum.ERROR, {duration: 3600});
            }
          );
        }
      });
    }
  }

  requestChanges(): void {
    const dialogRef = this.dialog.open(RequestChangesDialogComponent, {
      width: '30vw',
    });

    dialogRef.afterClosed().subscribe((result: ChangeRequestInfo) => {
      if (!!result) {
        console.log('Change Request Info', result);

        // this.quoteService.submitChangeRequest(changeRequestInfo);
      }
    })
  }

  downloadPdf(): void {
    window.open(this.pdfSrc);
  }

  initLoadCompleted(pdf: any): void {
    this.isLoading = false;
  }

  fileArrayChanged($event: File[]) {
    this.fileArray = $event;
  }

}
