<mat-card [formGroup]="approvalForm" class="flex flex-col p-6 h-full">
  <mat-card-title> Quote Approval Details </mat-card-title>

  <mat-checkbox formControlName="certified" class="mt-4 mb-2">By checking this box certify that I am authorized to approve this quote</mat-checkbox>
  <mat-error *ngIf="this.approvalForm.controls['certified'].touched && this.approvalForm.controls['certified'].hasError('required')">
    Check this box to submit quote approval
  </mat-error>

  <mat-form-field appearance="outline">
    <mat-label> Name </mat-label>
    <input type="text" matInput formControlName="name">
    <mat-error *ngIf="this.approvalForm.controls['name'].touched && this.approvalForm.controls['name'].hasError('required')">
      Name is a required field
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label> Email </mat-label>
    <input type="text" matInput formControlName="email">
    <mat-error *ngIf="this.approvalForm.controls['email'].touched && this.approvalForm.controls['email'].hasError('required')">
      Email is a required field
    </mat-error>
  </mat-form-field>

  <div>
    <ng-container *ngIf="!existingEstimate">
      <mat-form-field class="w-1/2" appearance="outline" hideRequiredMarker="true">
        <mat-label>Install Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="installDate"/>
        <mat-error *ngIf="approvalForm.get('installDate')?.hasError('required')"> Install Date is required </mat-error>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="existingEstimate">
      <mat-form-field class="w-1/2" appearance="outline" hideRequiredMarker="true">
        <mat-label>Estimated Date</mat-label>
        <mat-select [compareWith]="compareDates" formControlName="estimateDate">
          <mat-option *ngFor="let option of dateOptions" [value]="option.date">{{ option.description }}</mat-option>
        </mat-select>
        <mat-error *ngIf="approvalForm.get('installDate')?.hasError('required')"> Estimate Date is required </mat-error>
      </mat-form-field>
    </ng-container>
    <mat-checkbox
      class="w-1/2 m-auto"
      [checked]="existingEstimate"
      (change)="updateEstimateCheck($event)"
      color="primary"
      #estimatedInstallCheckbox>Estimated Install</mat-checkbox>
  </div>

  <mat-form-field appearance="outline">
    <mat-label> PO# </mat-label>
    <input type="text" matInput formControlName="po">
  </mat-form-field>

  <app-file-upload
    (fileChangeEmitter)="this.fileChangeEmitter.emit($event)"></app-file-upload>



</mat-card>


