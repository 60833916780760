<div class="w-full h-full translate-y-1/2 translate-x-1/2" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="flex flex-row space-x-2">
  <app-quote-approval [dateOptions]="dateOptions"
                      [approvalForm]="approvalForm"
                      (fileChangeEmitter)="fileArrayChanged($event)"
                      class="ml-2 mt-2 mat-elevation-z4 h-1/2"></app-quote-approval>

  <div class="h-full flex flex-col space-y-2 justify-center">

    <pdf-viewer [src]="this.pdfSrc"
                [rotation]="0"
                [original-size]="false"
                [show-all]="true"
                [fit-to-page]="false"
                [zoom]="95/100"
                [zoom-scale]="'page-width'"
                [stick-to-page]="false"
                [render-text]="true"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                (after-load-complete)="initLoadCompleted($event)"
                class="mat-elevation-z4 self-center mt-2"
                style="width: 70vw; height: 90vh; max-width: 750px; max-height: 900px">
    </pdf-viewer>
    <div class="flex flex-row space-x-2 self-end">
      <button mat-raised-button (click)="downloadPdf()">DOWNLOAD PDF</button>
      <button mat-raised-button color="warn" (click)="requestChanges()">REQUEST CHANGES</button>
      <button mat-raised-button color="primary" (click)="approveQuote()">APPROVE</button>
    </div>
  </div>
</div>




