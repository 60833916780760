import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Quote} from '../models/quote.interface';
import {ChangeRequestInfo} from "../models/change-request-info.interface";
import {EstimateDateOption} from "../models/estimate-date-option.interface";

@Injectable({
	providedIn: 'root'
})
export class QuoteService {
	baseUrl: string = `${environment.url}/quote`;
  private dateRangeOptions: EstimateDateOption[] = [];

	constructor(private http: HttpClient) {
    this.createDateRangeOptions();
  }

	findOne(id: number): Observable<Quote> {
		return this.http.get<Quote>(`${this.baseUrl}/${id}`);
	}

	getPdf(id: number): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/${id}/pdf`);
  }

  submitApproval(id: number, formData: FormData): Observable<boolean> {
    return this.http.post<any>(`${this.baseUrl}/${id}/approve`, formData);
  }

  requestChanges(changeRequestInfo: ChangeRequestInfo): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/changes-requested`, changeRequestInfo);
  }

  getDateRangeOptions(): EstimateDateOption[] {
    return this.dateRangeOptions;
  }

  createDateRangeOptions(): void {
    let now: Date = new Date();
    const currentMonth: number = now.getMonth();
    //If the date is > 15, set it to the 1st so that first half of month still an option
    now.setHours(0, 0, 0, 0);
    if (now.getUTCDate() < 15) {
      now.setDate(1);
    }

    for (let i = currentMonth; i < currentMonth + 12; i++) {
      const targetMonth: number = i % 12;
      const targetYear: number = now.getFullYear() + Math.floor(i / 12);

      const firstOfMonth: Date = new Date(targetYear, targetMonth, 1);
      const fifteenthOfMonth: Date = new Date(targetYear, targetMonth, 15);

      if (now <= firstOfMonth) {
        this.dateRangeOptions.push({
          date: firstOfMonth,
          description: firstOfMonth.toLocaleString('default', {month: 'long'}) + ' - First Half'
        });
      }
      if (now <= fifteenthOfMonth) {
        this.dateRangeOptions.push({
          date: fifteenthOfMonth,
          description: firstOfMonth.toLocaleString('default', {month: 'long'}) + ' - Second Half'
        });
      }
    }
  }
}
