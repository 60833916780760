<div class="min-h-full h-max bg-primary-500 p-5">
  <div class="flex flex-row justify-end">
    <button mat-raised-button class="add-bid" (click)=addBid()>+Add Bid</button>
  </div>
  <div class="flex flex-row gap-5 mt-5">
    <div class="flex flex-col flex-1 gap-5">
      <mat-card class="card">
        <div class="flex flex-row gap-5">
          <div class="flex flex-col flex-1">
            <div class="text-base">Delivery Date:</div>
            <div class="font-medium text-base">{{deliveryDateFormatted}}</div>
          </div>
          <div class="flex flex-col flex-1">
            <div class="text-base">Destination</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationName']}}</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationAddress1']}}</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationCity']}}
              , {{shipments[0]['destinationState']}} {{shipments[0]['destinationPostal']}}</div>
          </div>
        </div>
        <div class="flex flex-col flex-1 gap-5">
          <h1>Shipments</h1>
          <mat-accordion multi class="w-full">
            <ng-container *ngFor="let shipment of shipments; index as i">
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span>{{shipment.originName}}</span>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="flex flex-col gap-5">
                  <div class="flex flex-row gap-5">
                    <div class="flex flex-col flex-1">
                      <div>Origin</div>
                      <div class="font-medium text-base">{{shipment.originName}}</div>
                      <div class="font-medium text-base">{{shipment.originAddress1}}</div>
                      <div class="font-medium text-base">{{shipment.originCity}}
                        , {{shipment.originState}} {{shipment.originPostal}}</div>
                    </div>
                    <div class="flex flex-col flex-1">
                      <div>Special Services</div>
                      <ng-container *ngFor="let accessorial of shipment.shipmentAccessorials; index as i">
                        <div class="font-medium text-base">{{accessorial.name}}</div>
                      </ng-container>
                    </div>
                    <div class="flex flex-col flex-1">
                      <div class="flex flex-row items-center gap-1">
                        <div>Linear Dimension:</div>
                        <div class="font-medium text-base">{{shipment.totalLinearDimension}}</div>
                      </div>
                      <div class="flex flex-row items-center gap-1">
                        <div>Weight:</div>
                        <div class="font-medium text-base">{{shipment.totalWeight}}</div>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <div>Handling Units</div>
                      <ng-container *ngFor="let handlingUnit of shipment.shipmentHandlingUnits; index as i">
                        <div class="font-medium text-base">
                          {{handlingUnit.qty}}x - {{handlingUnit.huType}}:
                          {{handlingUnit.length}}x{{handlingUnit.width}}x{{handlingUnit.height}}
                          {{handlingUnit.dUnit}}, {{handlingUnit.weight}} {{handlingUnit.wUnit}}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
      </mat-card>

      <mat-card>
        <div class="flex flex-row justify-center p-2 gap-4">
          <button mat-raised-button class="submit-bids" [disabled]="bids.length === 0" (click)="submitBids()">Submit
            Bids
          </button>
          <button mat-raised-button class="decline-request" (click)="declineBidRequest()">Decline Request</button>
        </div>
      </mat-card>
    </div>
    <div class="flex flex-col flex-1 gap-5">

      <form [formGroup]="freightBrokerForm">
        <mat-accordion>
          <ng-container *ngFor="let bid of bids.controls; index as i">
            <mat-expansion-panel [formGroup]="getFormGroup(bid)" [expanded]="i === expandedIndex">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{bid.value.freightBidNo}}
                </mat-panel-title>
                <mat-panel-description>
                  {{getBidStatus(bid)}}
                </mat-panel-description>
                <button mat-raised-button class="remove-bid" (click)=removeBid(i)>Remove Bid</button>
              </mat-expansion-panel-header>
              <div class="flex flex-row">
                <mat-form-field matTooltip="This is your reference # to the bid." class="w-1/3">
                  <mat-label>Quote #</mat-label>
                  <input matInput formControlName="freightBidNo" type="text"/>
                  <mat-error>Quote # <strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-row">
                <mat-form-field class="w-2/3">
                  <mat-label>Carrier Name</mat-label>
                  <input matInput formControlName="carrierName" type="text"/>
                  <mat-error>Carrier Name <strong>required</strong>.</mat-error>
                </mat-form-field>
                <mat-form-field class="w-1/3">
                  <mat-label>Carrier SCAC</mat-label>
                  <input matInput formControlName="carrierScac" type="text"/>
                  <mat-error>Carrier SCAC <strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-row">
                <mat-form-field class="w-2/3">
                  <mat-label>Broker Name</mat-label>
                  <input matInput formControlName="brokerName" type="text"/>
                  <mat-error>Broker Name <strong>required</strong>.</mat-error>
                </mat-form-field>
                <mat-form-field class="w-1/3">
                  <mat-label>Broker SCAC</mat-label>
                  <input matInput formControlName="brokerScac" type="text"/>
                  <mat-error>Broker SCAC <strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-row">
                <mat-form-field appearance="outline" class="w-1/3">
                  <mat-label class="font-medium">Pickup Date</mat-label>
                  <input matInput [min]="minDate" [matDatepicker]="datepicker" formControlName="pickupDatetime"/>
                  <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                  <mat-datepicker #datepicker>
                  </mat-datepicker>
                  <mat-error>Date <strong>required</strong>.</mat-error>
                </mat-form-field>
                <mat-form-field class="w-1/3">
                  <mat-label>Transit Time</mat-label>
                  <input matInput formControlName="transitTime" type="text"/>
                  <mat-error>Transit Time <strong>required</strong>.</mat-error>
                </mat-form-field>
                <div class="my-auto mx-4 pb-4">Day(s)</div>
              </div>
              <div class="flex flex-row gap-4 justify-center pb-4">
                <div class="flex flex-col w-1/4">
                  <mat-label>Equipment Type:</mat-label>
                  <mat-button-toggle-group formControlName="equipmentType" name="equipmentType">
                    <mat-button-toggle matTooltip="Full Truckload" value="ftl">FTL</mat-button-toggle>
                    <mat-button-toggle matTooltip="Less than Truckload" value="ltl">LTL</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="flex flex-col">
                  <mat-label>Service Type:</mat-label>
                  <mat-button-toggle-group formControlName="serviceType" name="serviceType">
                    <mat-button-toggle value="standard">Standard</mat-button-toggle>
                    <mat-button-toggle value="expedited">Expedited</mat-button-toggle>
                    <mat-button-toggle value="guaranteed">Guaranteed</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="flex flex-col  w-1/4">
                  <mat-label>Direct/Indirect Service:</mat-label>
                  <mat-button-toggle-group formControlName="indirectService" name="indirectService">
                    <mat-button-toggle [value]="false">Direct</mat-button-toggle>
                    <mat-button-toggle [value]="true">Indirect</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="flex flex-row">
                <mat-form-field class="w-full h-44">
                  <mat-label>Comments</mat-label>
                  <textarea class="comments" matInput formControlName="comments" type="text"></textarea>
                  <mat-error>Comments<strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-row justify-center">
                <mat-form-field>
                  <mat-label>All In Price</mat-label>
                  <mat-select formControlName="currency">
                    <mat-option value="USD">USD</mat-option>
                    <mat-option value="CAD">CAD</mat-option>
                    <mat-option value="MXN">MXN</mat-option>
                  </mat-select>
                  <mat-error>All In Price <strong>required</strong>.</mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Amount</mat-label>
                  <input matInput formControlName="amount" type="number"/>
                  <mat-error>Amount <strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-row justify-center">
                <button mat-raised-button class="save-bid" (click)=saveBid(bid)>Save Bid</button>
              </div>
            </mat-expansion-panel>

          </ng-container>
        </mat-accordion>

      </form>
    </div>
  </div>
</div>













