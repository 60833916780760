import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {EstimateDateOption} from "../../core/models/estimate-date-option.interface";

@Component({
  selector: 'app-quote-approval',
  templateUrl: './quote-approval.component.html',
  styleUrls: ['./quote-approval.component.scss']
})
export class QuoteApprovalComponent {
  @ViewChild('estimatedInstallCheckbox') estimatedInstallCheckbox!: MatCheckbox;
  @Input() dateOptions: EstimateDateOption[] = [];
  @Input() approvalForm: FormGroup = new FormGroup({});
  @Output() fileChangeEmitter = new EventEmitter<File[]>();

  existingEstimate: boolean = false;

  constructor() {}

  compareDates(date1: Date, date2: string): boolean {
    const parsedDate = new Date(date2);
    return date1.getTime() === parsedDate.getTime();
  }

  updateEstimateCheck(change: MatCheckboxChange): void {
    this.approvalForm.controls['installDate'].setValue('');
    this.approvalForm.controls['estimateDate'].setValue('');
    this.existingEstimate = change.checked;
  }

}
