<mat-card [formGroup]="changeRequestForm" class="flex flex-col p-6">
  <mat-card-title> Request Modifications </mat-card-title>

  <mat-form-field appearance="outline" class="mt-4">
    <mat-label> Name </mat-label>
    <input formControlName="name" matInput>
    <mat-error *ngIf="this.changeRequestForm.controls['name'].touched &&
    this.changeRequestForm.controls['name'].hasError('required')">
      This is a required field
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label> Description </mat-label>
    <textarea matInput formControlName="changeDescription"></textarea>
    <mat-error *ngIf="this.changeRequestForm.controls['changeDescription'].touched &&
    this.changeRequestForm.controls['changeDescription'].hasError('required')">
      This is a required field
    </mat-error>
  </mat-form-field>

  <mat-checkbox formControlName="floorPlanModification"> Floor Plan Modification Required </mat-checkbox>

  <div class="flex flex-row space-x-2 mt-6 self-end">
    <button mat-raised-button mat-button (click)="close()"> CANCEL </button>
    <button mat-raised-button mat-button color="primary" (click)="sendRequest()"> SEND REQUEST </button>
  </div>

</mat-card>


