import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {HttpClientModule} from "@angular/common/http";
import {ShipmentService} from "./core/services/shipment.service";
import {BidsModule} from "./bids/bids.module";
import {AuthInterceptorProviders} from './core/interceptors/auth.interceptor';
import { QuoteComponent } from './quote/quote.component';
import {QuoteService} from './core/services/quote.service';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MatButtonModule} from "@angular/material/button";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatNativeDateModule} from "@angular/material/core";
import {BookingsModule} from "./bookings/bookings.module";
import { ApproveDialogComponent } from './quote/dialogs/approve-dialog/approve-dialog.component';
import { RequestChangesDialogComponent } from './quote/dialogs/request-changes-dialog/request-changes-dialog.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import { FileUploadComponent } from './quote/file-upload/file-upload.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatIconModule} from "@angular/material/icon";
import { QuoteApprovalComponent } from './quote/quote-approval/quote-approval.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ThankYouComponent } from './thank-you/thank-you.component';


@NgModule({
  declarations: [
    AppComponent,
    ThankYouComponent,
    AppComponent,
    QuoteComponent,
    ApproveDialogComponent,
    RequestChangesDialogComponent,
    FileUploadComponent,
    QuoteApprovalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BidsModule,
    BookingsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    PdfViewerModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCardModule,
    MatDatepickerModule,
    MatSelectModule,
    MatExpansionModule,
    NgxDropzoneModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule
  ],
  providers: [
    ShipmentService,
    AuthInterceptorProviders,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    QuoteService,
    AuthInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
