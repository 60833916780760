import {Component, OnInit} from '@angular/core';
import {Shipment} from "../core/models/shipment.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FreightService} from "../core/services/freight.service";
import {SnackbarActionEnum} from "../core/enums/snackbar-action.enum";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Freight} from "../core/models/freight.interface";
import {FreightBid} from "../core/models/freight-bid.interface";
import {FreightBidStatusEnum} from "../core/enums/freight-bid-status.enum";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  shipments: Shipment[] = [new Shipment()];
  bookingForm: FormGroup = new FormGroup({});

  freightBrokerId: string = '';

  freightBid: FreightBid = {
    amount: 0
  };

  price: string = '';

  deliveryDate: string = '';
  pickupDate: string = '';


  constructor(private route: ActivatedRoute, private freightService: FreightService,
              private fb: FormBuilder, private snackbar: MatSnackBar,
              private router: Router) {
  }
  ngOnInit() {
    this.bookingForm = this.fb.group({
      puOrderNo:'',
      proNo:'',
      comment:''
    })

    if (this.route) {
      this.route.params.subscribe(async (params) => {
        this.freightBrokerId = params['freightBrokerId'];
        this.freightService
          .findFreightByFreightBrokerId(+this.freightBrokerId)
          .subscribe((freight: any) => {
            this.setFreightBid(freight);
            this.shipments = freight.shipments;
            this.setPickupDeliveryDate(freight.shipments);
          });
      });
    }
  }

  setFreightBid(freight: Freight){
    const freightBids = freight?.freightBrokers?.at(0)?.freightBids;
    if(freightBids){
      this.freightBid = freightBids
        .filter(freightBid => freightBid.bidStatus === FreightBidStatusEnum.BOOKING_REQUESTED)[0];
      if(!this.freightBid){
        this.router.navigate(['/thank-you']);
      }
      if(this.freightBid?.amount){
        this.price = this.formatAmount(this.freightBid.amount, this.freightBid.currency ? this.freightBid.currency : 'USD');
      }
      if(this.freightBid?.pickupDatetime){
        this.pickupDate = new Date(this.freightBid?.pickupDatetime).toLocaleDateString('en-us', {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        });
      }
    }

  }

  formatAmount(amount: number, currency: string){
    const formatting_options = {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }
    const dollarString = new Intl.NumberFormat("en-US", formatting_options);
    return dollarString.format(amount);
  }

  setPickupDeliveryDate(shipments: Shipment[]) {
    if (shipments && shipments[0]) {
      if(shipments[0].deliveryDateTime){
        this.deliveryDate = new Date(shipments[0].deliveryDateTime)
          .toLocaleDateString('en-us', {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric"
          });
      }
      if(shipments[0].pickupDateTime) {
        this.deliveryDate = new Date(shipments[0].pickupDateTime)
          .toLocaleDateString('en-us', {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric"
          });

      }
    }
  }

  acceptBooking(){
    if(this.freightBid.id){
      this.freightService.acceptBooking(this.bookingForm.value, +this.freightBid.id).subscribe({
        next: () => {
          this.router.navigate(['/thank-you']).then(() => {
            this.snackbar.open('Booking Accepted', SnackbarActionEnum.SUCCESS,{
              duration: 2000
            });
          });
        },
        error: () => {
          this.snackBookingError();
        }
      });
    } else {
      this.snackBookingError();
    }
  }

  snackBookingError(): void {
    this.snackbar.open('Error Accepting Booking', SnackbarActionEnum.ERROR,{
      duration: 2000
    });
  }

  declineBooking(): void {
    this.freightService.declineBooking(this.freightBid).subscribe({
      next: () => {
        this.router.navigate(['/thank-you']);
      },
      error: () => {
        this.snackbar.open('Error Declining', SnackbarActionEnum.ERROR,{
          duration: 2000
        });
      }
    });
  }


}
