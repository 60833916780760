<div class="min-h-full h-max bg-primary-500 p-5">
  <div class="flex flex-row gap-5">
    <div class="flex flex-col flex-1 gap-5">
      <mat-card class="card">
        <div class="flex flex-row gap-5">
          <div class="flex flex-col flex-1">
            <div class="text-base">Delivery Date:</div>
            <div class="font-medium text-base">{{deliveryDate}}</div>
          </div>
          <div class="flex flex-col flex-1">
            <div class="text-base">Destination</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationName']}}</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationAddress1']}}</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationCity']}}
              , {{shipments[0]['destinationState']}} {{shipments[0]['destinationPostal']}}</div>
          </div>
        </div>
        <div class="flex flex-col flex-1 gap-5">
          <h1>Shipments</h1>
          <mat-accordion multi class="w-full">
            <ng-container *ngFor="let shipment of shipments; index as i">
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span>{{shipment.originName}}</span>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="flex flex-col gap-5">
                  <div class="flex flex-row gap-5">
                    <div class="flex flex-col flex-1">
                      <div>Origin</div>
                      <div class="font-medium text-base">{{shipment.originName}}</div>
                      <div class="font-medium text-base">{{shipment.originAddress1}}</div>
                      <div class="font-medium text-base">{{shipment.originCity}}
                        , {{shipment.originState}} {{shipment.originPostal}}</div>
                    </div>
                    <div class="flex flex-col flex-1">
                      <div>Special Services</div>
                      <ng-container *ngFor="let accessorial of shipment.shipmentAccessorials; index as i">
                        <div class="font-medium text-base">{{accessorial.name}}</div>
                      </ng-container>
                    </div>
                    <div class="flex flex-col flex-1">
                      <div class="flex flex-row items-center gap-1">
                        <div>Linear Dimension:</div>
                        <div class="font-medium text-base">{{shipment.totalLinearDimension}}</div>
                      </div>
                      <div class="flex flex-row items-center gap-1">
                        <div>Weight:</div>
                        <div class="font-medium text-base">{{shipment.totalWeight}}</div>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <div>Handling Units</div>
                      <ng-container *ngFor="let handlingUnit of shipment.shipmentHandlingUnits; index as i">
                        <div class="font-medium text-base">
                          {{handlingUnit.qty}}x - {{handlingUnit.huType}}:
                          {{handlingUnit.length}}x{{handlingUnit.width}}x{{handlingUnit.height}}
                          {{handlingUnit.dUnit}}, {{handlingUnit.weight}} {{handlingUnit.wUnit}}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
      </mat-card>

    </div>
    <div class="flex flex-col flex-1 gap-5">
      <mat-card class="card flex flex-col gap-7">
        <div class="flex flex-row">
          <div class="flex flex-col w-1/4">
            <div class="text-base">Price</div>
            <div class="font-medium text-2xl">{{price}}</div>
          </div>
          <div class="flex flex-col w-1/4">
            <div>Pickup Date:</div>
            <div class="font-medium text-base">{{pickupDate}}</div>
          </div>
          <div class="flex flex-col w-1/4">
            <div>Delivery Date:</div>
            <div class="font-medium text-base">{{deliveryDate}}</div>
          </div>
          <div class="flex flex-col w-1/4">
            <div>Transit:</div>
            <div class="font-medium text-base">{{freightBid.transitTime ? freightBid.transitTime : 'N/A'}} days</div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="flex flex-col flex-1">
            <div>Quote #</div>
            <div class="font-medium text-base">{{freightBid.freightBidNo ? freightBid.freightBidNo : 'N/A'}}</div>
          </div>
          <div class="flex flex-col flex-1">
            <div>Equipment Type</div>
            <div class="font-medium text-base">{{freightBid.equipmentType ? freightBid.equipmentType : 'N/A'}}</div>
          </div>
          <div class="flex flex-col flex-1">
            <div>Service Type</div>
            <div class="font-medium text-base">{{freightBid.serviceType ? freightBid.serviceType : 'N/A'}}</div>
          </div>
          <div class="flex flex-col flex-1">
            <div>Indirect/Direct Service</div>
            <div class="font-medium text-base">{{freightBid.indirectService ? 'Indirect' : 'direct'}}</div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="flex flex-col w-1/4 gap-2">
            <div class="flex flex-col flex-1">
              <div>Carrier Name</div>
              <div class="font-medium text-base">{{freightBid.carrierName ? freightBid.carrierName : 'N/A'}}</div>
            </div>
            <div class="flex flex-col flex-1">
              <div>Carrier SCAC</div>
              <div class="font-medium text-base">{{freightBid.carrierScac ? freightBid.carrierScac : 'N/A'}}</div>
            </div>
          </div>
          <div class="flex flex-col w-1/4 gap-2">
            <div class="flex flex-col flex-1">
              <div>Broker Name</div>
              <div class="font-medium text-base">{{freightBid.brokerName ? freightBid.brokerName : 'N/A'}}</div>
            </div>
            <div class="flex flex-col flex-1">
              <div>Broker SCAC</div>
              <div class="font-medium text-base">{{freightBid.brokerScac ? freightBid.brokerScac : 'N/A'}}</div>
            </div>
          </div>
          <div class="flex flex-col w-1/2">
            <div>Bid Comments</div>
            <div class="font-medium text-base">{{freightBid.comments ? freightBid.comments : 'N/A'}}</div>
          </div>
        </div>
      </mat-card>
      <mat-card class="card" [formGroup]="bookingForm">
        <div class="flex flex-row gap-5">
          <mat-form-field matTooltip="This is your P/U reference." class="w-1/3">
            <mat-label>P/U Order #</mat-label>
            <input matInput formControlName="puOrderNo" type="text"/>
            <mat-error>P/U Order # <strong>required</strong>.</mat-error>
          </mat-form-field>
          <mat-form-field matTooltip="This is your PRO # reference." class="w-1/3">
            <mat-label>Pro #</mat-label>
            <input matInput formControlName="proNo" type="text"/>
            <mat-error>Pro # <strong>required</strong>.</mat-error>
          </mat-form-field>
        </div>
        <div class="flex flex-row gap-5">
          <mat-form-field class="w-full h-44">
            <mat-label>Comments</mat-label>
            <textarea class="comments" matInput formControlName="comment" type="text"></textarea>
            <mat-error>Comments<strong>required</strong>.</mat-error>
          </mat-form-field>
        </div>
      </mat-card>
      <mat-card>
        <div class="flex flex-row justify-center p-2 gap-4">
          <button mat-raised-button class="accept-booking" (click)="acceptBooking()">Accept Booking</button>
          <button mat-raised-button class="decline-request" (click)="declineBooking()">Decline Request</button>
        </div>
      </mat-card>
    </div>
  </div>
</div>













