import {Component, OnInit} from '@angular/core';
import {Shipment} from "../core/models/shipment.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {FreightService} from "../core/services/freight.service";
import {AbstractControl, FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {Freight} from "../core/models/freight.interface";
import {FreightBid} from "../core/models/freight-bid.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarActionEnum} from "../core/enums/snackbar-action.enum";
import {EquipmentTypeEnum} from "../core/enums/equipment-type.enum";

@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss']
})
export class BidsComponent implements OnInit {

  shipments: Shipment[] = [new Shipment()];
  freight: Freight = {};

  freightBrokerId: string = '';

  minDate = new Date();

  expandedIndex: number = 0;

  deliveryDate: Date | null = null;
  deliveryDateFormatted: string = '';


  freightBrokerForm: FormGroup = new FormGroup({
    bids: new FormArray([])
  });


  constructor(private route: ActivatedRoute, private freightService: FreightService,
              private fb: FormBuilder, private snackbar: MatSnackBar,
              private router: Router) {
  }


  getFormGroup(item: AbstractControl<FreightBid>): FormGroup {
    return item as FormGroup;
  }

  saveBid(bid: AbstractControl<FreightBid>) {
    this.freightService.saveFreightBid(bid.value).subscribe({
      next: (freightBid: FreightBid) => {
        bid.value.id = freightBid.id;
        bid.value.bidStatus = freightBid.bidStatus;
        this.snackbar.open('Bid Saved', SnackbarActionEnum.SUCCESS, {
          duration: 2000
        });
      },
      error: () => {
        this.snackbar.open('Error Saving Bid', SnackbarActionEnum.ERROR, {
          duration: 2000
        });
      }
    });
  }

  submitBids() {
    this.freightService.submitFreightBids(this.bids.value).subscribe({
      next: () => {
        this.loadInitialFreight();
        this.snackbar.open('Bid Submitted', SnackbarActionEnum.SUCCESS,{
          duration: 2000
        });
      },
      error: () => {
        this.snackbar.open('Error Submitting Bid', SnackbarActionEnum.ERROR,{
          duration: 2000
        });
      }
    });
  }

  getBidStatus(bid: AbstractControl<FreightBid>) {
    return bid.value.bidStatus ? bid.value.bidStatus.toUpperCase() : null;
  }

  removeBid(index: number) {
    let id = this.bids.at(index).value.id;
    if (id) {
      this.freightService.removeFreightBid(id).subscribe({
        next: () => {
          this.bids.removeAt(index);
          this.snackbar.open('Bid Removed', SnackbarActionEnum.SUCCESS,{
            duration: 2000
          });
        },
        error: () => {
          this.snackbar.open('Error Removing Bid', SnackbarActionEnum.ERROR,{
            duration: 2000
          });
        }
      });
    }


  }

  ngOnInit() {
    if (this.route) {
      this.route.params.subscribe(async (params) => {
        this.freightBrokerId = params['freightBrokerId'];
        this.loadInitialFreight();
      });
    }
  }

  loadInitialFreight(): void {
    this.freightService
      .findFreightByFreightBrokerId(+this.freightBrokerId)
      .subscribe((freight: any) => {
        this.bids.clear();
        this.freight = freight;
        this.shipments = freight.shipments;
        this.setDeliveryDate(freight.shipments);
        this.generateBrokerForm(freight);
      });
  }

  setDeliveryDate(shipments: Shipment[]) {
    if (shipments && shipments[0] && shipments[0].deliveryDateTime) {
      this.deliveryDate = new Date(shipments[0].deliveryDateTime);
      this.deliveryDateFormatted = this.deliveryDate
        .toLocaleDateString('en-us', {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        })
    }
  }

  addBid() {
    this.bids.push(
      this.fb.group({
        id: null,
        bidStatus: null,
        freightBidNo: null,
        carrierName: null,
        carrierScac: null,
        brokerName: null,
        brokerScac: null,
        deliveryDatetime: this.deliveryDate,
        deliveryTimezone: null,
        pickupDatetime: new Date(),
        transitTime: null,
        serviceType: 'standard',
        indirectService: false,
        comments: null,
        currency: 'USD',
        equipmentType: EquipmentTypeEnum.FTL,
        amount: null,
        freightBrokerId: this.freightBrokerId
      })
    );
    this.expandedIndex = this.bids.length - 1;
  }

  generateBrokerForm(freight: Freight) {
    if (freight?.freightBrokers?.[0]?.freightBids && freight.freightBrokers[0].freightBids.length > 0) {
      freight.freightBrokers[0].freightBids.forEach((bid: FreightBid) => {
        this.bids.push(this.bidToFormGroup(bid));
      })
    } else {
      // There are no previous bids, add a bid as a starting place for the broker/carrier
      this.addBid();
    }
  }

  bidToFormGroup(bid: FreightBid): FormGroup{
    return this.fb.group({
      id: bid.id,
      bidStatus: bid.bidStatus,
      freightBidNo: bid.freightBidNo,
      carrierName: bid.carrierName,
      carrierScac: bid.carrierScac,
      brokerName: bid.brokerName,
      brokerScac: bid.brokerScac,
      deliveryDatetime: bid.deliveryDatetime,
      deliveryTimezone: bid.deliveryTimezone,
      pickupDatetime: bid.pickupDatetime,
      transitTime: bid.transitTime,
      serviceType: bid.serviceType,
      equipmentType: bid.equipmentType,
      indirectService: bid.indirectService,
      comments: bid.comments,
      currency: bid.currency,
      amount: bid.amount,
      freightBrokerId: bid.freightBrokerId
    })
  }

  get bids(): FormArray {
    return this.freightBrokerForm.get('bids') as FormArray;
  }

  declineBidRequest(): void {
    this.freightService.declineBidRequest(+this.freightBrokerId).subscribe({
      next: () => {
        this.router.navigate(['/thank-you']);
      },
      error: () => {
        this.snackbar.open('Error Declining', SnackbarActionEnum.ERROR,{
          duration: 2000
        });
      }
    });
  }

}
